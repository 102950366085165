<template>
  <CommonsModalsModalBody height="auto" :svg="svg">
    <template #title>
      <div>Importez un document</div>
    </template>

    <template #help> Formats autorisés : .pdf, .png, .jpeg, .jpg, .xls, .xlsx, .docx </template>

    <template #content>
      <div class="modal-content">
        <CommonsDropzoneContainer
          :extensions="[
            'application/pdf',
            'image/png',
            'image/jpeg',
            'image/jpg',
            'text/csv',
            'text/plain',
          ]"
          :max-files="1"
          :max-size="20"
          :disabled="isLoading"
          :show-drop-box-button="true"
          @updated="setFiles($event)"
        />
        <uds-input-select-auto-popper
          label="Catégorie"
          is-required
          :value="idGedCategory"
          :items="categories"
          :error="err.idGedCategory"
          @select="idGedCategory = $event || null"
        />
        <uds-input-select-auto-popper
          label="Sous-catégorie"
          is-required
          :value="newDocumentForm.idGedFolder"
          :items="gedFolders"
          item-value="id"
          item-text="name"
          :error="err.idGedFolder"
          :disabled="idGedCategory === GedCategory.BANK_STATEMENT"
          @select="newDocumentForm.idGedFolder = $event || null"
        />
        <uds-input-string
          label="Nom du document"
          :value="newDocumentForm.name"
          is-required
          :error="err.name"
          @change="newDocumentForm.name = $event ?? null"
        />
        <uds-input-calendar
          label="Date d'émission du document"
          :date="newDocumentForm.emissionDate"
          @change-date="newDocumentForm.emissionDate = $event"
        />
      </div>
    </template>

    <template #footer>
      <uds-main-button type="tertiary" size="small" @click="$emit('cancel')">
        Annuler
      </uds-main-button>
      <uds-main-button
        size="small"
        :disabled="!isFormValidWithDocument"
        :loading="isLoading"
        @click="isFormValidWithDocument ? createDocument() : null"
      >
        Enregistrer
      </uds-main-button>
    </template>
  </CommonsModalsModalBody>
</template>

<script setup lang="ts">
import {
  FileType,
  GedCategory,
  gedCategoryWithName,
  GedFolder,
  GedFolderList,
} from "@silexpert/core";

type DocumentForm = {
  document: File | null;
  idGedCategory: number | null;
  idGedFolder: number | null;
  name: string | null;
  emissionDate?: string;
};

const emit = defineEmits(["cancel", "close"]);

const { isComptalib, hasOneOfTheseBrands } = useBrandsComposable();
const dayjs = useDayjs();
const route = useRoute();
const libraryStore = useLibraryStore();
const societyStore = useSocietyStore();
const userStore = useUsersStore();

const svg = isComptalib() ? "ComptalibBriefCaseSvg" : "DocumentSvg";

const isLoading = ref(false);

const files = ref<File[]>([]);
const newDocumentForm = ref<DocumentForm>({
  document: null,
  idGedCategory: null,
  idGedFolder: null,
  name: null,
  emissionDate: dayjs().format("YYYY-MM-DD"),
});

const idGedCategory = computed({
  get() {
    return newDocumentForm.value.idGedCategory;
  },
  set(newValue) {
    newDocumentForm.value.idGedCategory = newValue;

    // remove the chosen folder, since the list of selectable folders is dependent on the chosen category
    newDocumentForm.value.idGedFolder = null;

    // if the idGedCategory of bankStatement is selected, then the folder Relevés bancaires is automatically selected
    if (newValue === GedCategory.BANK_STATEMENT) {
      newDocumentForm.value.idGedFolder = GedFolder.BANK_STATEMENT;
    }
  },
});
const err = computed<{ [key: string]: string | null }>(() => {
  return {
    idGedCategory: !isDefined(idGedCategory.value) ? "Le champ est requis." : null,
    idGedFolder: !isDefined(newDocumentForm.value.idGedFolder) ? "Le champ est requis." : null,
    name: !isDefined(newDocumentForm.value.name) ? "Le champ est requis." : null,
  };
});

const isValid = computed<boolean>(() => {
  for (const property in err.value) {
    const value = err.value[property];
    if (isDefined(value)) {
      return false;
    }
  }
  return true;
});

const isFormValidWithDocument = computed<boolean>(() => isValid.value && files.value.length > 0);
// const isPickerDisabled = computed<boolean>(() => files.value.length >= 1);
const categories = ref(
  gedCategoryWithName
    .filter(
      (item) =>
        item.usableByClient &&
        (isDefined(item.brandCondition)
          ? // @ts-expect-error
            hasOneOfTheseBrands(item.brandCondition)
          : true),
    )
    .map((it) => ({
      text: it.name,
      value: it.idGedCategory,
    })),
);

const gedFolders = computed(() => {
  return GedFolderList.filter(
    (item) => item.idGedCategory === newDocumentForm.value.idGedCategory && item.isVisibleForClient,
  ).sort((a, b) => {
    return a.name < b.name ? -1 : 1;
  });
});

const fileExtension = ref<string>();
function setFiles(newValue: File[]) {
  files.value = newValue;
  if (isArray(newValue) && newValue.length > 0) {
    const newFile = newValue[0] as File;
    newDocumentForm.value.document = newFile;
    newDocumentForm.value.name = newFile.name;

    // Get the file extension
    const extension = newDocumentForm.value.name?.split(".").pop();

    // Save the file extension use the enum
    fileExtension.value =
      Object.values(FileType).find((ft) => ft.name === newFile.type)?.extension ?? extension;

    // Set the name of the document to the name of the file without the extension
    newDocumentForm.value.name = newDocumentForm.value.name?.replace(`.${extension}`, "") ?? "";
  } else {
    newDocumentForm.value.document = null;
    newDocumentForm.value.name = null;
  }
}

function modifyFileName(value: string, extension: string) {
  const regex = /[^a-zA-Z0-9\s|\-|_$]+/g;

  let result = value.replaceAll(regex, "");

  // Remove extension from the name
  result = result.replace(`${extension}`, "");

  return `${result}.${extension}`;
}

async function createDocument() {
  if (!isDefined(newDocumentForm.value.idGedFolder) || !isDefined(newDocumentForm.value.name)) {
    return;
  }

  isLoading.value = true;

  const idSociety = societyStore.society!.id!;
  const idUser = userStore.user!.id!;

  const formData = new FormData();
  formData.append("file", files.value[0]);
  // @ts-expect-error
  formData.append("idSociety", idSociety);
  // @ts-expect-error
  formData.append("idGedFolder", newDocumentForm.value.idGedFolder);
  // @ts-expect-error
  formData.append("idUser", idUser);
  // @ts-expect-error
  formData.append("emissionDate", dayjs(newDocumentForm.value.emissionDate));
  formData.append(
    "filename",
    modifyFileName(newDocumentForm.value.name, fileExtension.value ?? ""),
  );
  // @ts-expect-error
  formData.append("typeFile", idGedCategory.value);

  await $silex()
    .library.create(formData)
    .then(() => {
      $notifier().open({ type: "success", content: "Document ajouté avec succès" });
      if (route.path.includes("library")) {
        libraryStore.fetchDocuments();
      }

      isLoading.value = false;

      emit("close");
    })
    .catch((error: any) => {
      isLoading.value = false;
      $notifier().open({ type: "error", content: apiErrorToString(error) });
    });
}
</script>

<style lang="scss" scoped>
.modal-content {
  display: flex;
  flex-direction: column;
  gap: $uds-spacing-1;
}
.footer-actions {
  text-align: right;
}
</style>
